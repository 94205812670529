import { Text } from "@radix-ui/themes";
import React, { CSSProperties } from "react";
import styles from "./TextV2.module.scss"; // Import the SCSS module
import {
  getSize,
  ResponsiveTextStyle,
  TextColor,
  TextStyle,
} from "@/design-system/components/text/TextStyle";
import type { TextProps as RadixTextProps } from "@radix-ui/themes";
import classNames from "classnames";

type TextElement = React.ElementRef<"span">;
type TextOwnProps = {
  textStyle: TextStyle | ResponsiveTextStyle;
  weightStyle?: "light" | "regular" | "medium" | "bold";
  appearance?: TextAppearance;
  clamp?: number;
  color?: TextColor;
};
type TextAppearance = "destructive" | "muted" | "normal";

interface TextProps
  extends Omit<RadixTextProps, "size" | "weight" | "color">,
    TextOwnProps {
  htmlFor?: string;
}

const TextV2 = React.forwardRef<TextElement, TextProps>(
  (
    {
      textStyle,
      weightStyle,
      style: incomingStyle,
      className,
      appearance = "normal",
      clamp,
      color,
      ...rest
    },
    ref,
  ) => {
    // Merge the incoming style with the styles from getStyledOverrides
    const { style: overridesStyle, ...overridesRest } = getStyledOverrides({
      textStyle,
      weightStyle,
      ...rest,
    });

    const combinedStyles: CSSProperties = {
      ...overridesStyle,
      ...incomingStyle,
      ...(clamp ? { "--clamp-lines": clamp } : {}), // Set the CSS variable if clamp is provided
    };

    let appearanceClasses = "";
    switch (appearance) {
      case "destructive":
        appearanceClasses = "text-destructive font-medium";
        break;
      case "muted":
        appearanceClasses = "text-muted-foreground";
        break;
      default:
        break;
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Text
        ref={ref}
        {...rest}
        {...overridesRest}
        style={combinedStyles}
        className={
          classNames(
            className,
            appearanceClasses,
            {
              [styles.lineClamp]: clamp,
              [styles.grey]: color === "gray"
            }
          )
        }
      />
    );
  },
);

TextV2.displayName = "TextV2";

function getStyledOverrides({ textStyle, weightStyle }: TextOwnProps): {
  size: RadixTextProps["size"];
  weight: RadixTextProps["weight"];
  style: CSSProperties;
} {
  let size: RadixTextProps["size"];
  if (textStyle && typeof textStyle !== "object") {
    size = {
      initial: getSize(textStyle, "initial"),
      sm: getSize(textStyle, "sm"),
      md: getSize(textStyle, "md"),
      lg: getSize(textStyle, "lg"),
    };
  } else {
    // Apply responsive sizes
    size = {
      initial: getSize(textStyle.initial, "initial"),
      sm: getSize(textStyle.sm, "sm"),
      md: getSize(textStyle.md, "md"),
      lg: getSize(textStyle.lg, "lg"),
    };
  }
  const style: CSSProperties = {};
  if (textStyle === "Label XS") {
    style.fontFamily = "helvetica";
  }

  const weight = weightStyle ? weightStyle : textStyle == "Body L" ? "medium" : "regular";

  return { size, weight, style };
}

export { TextV2 };
export type { TextProps };
export type { TextStyle as TextV2TextStyle };
